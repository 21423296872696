import { CalligraphyScreen } from "./components/Calligraphy/Calligraphy";
import { TypingScreen } from "./components/TypingScreen";
import { BrowserRouter, Route, Routes,  } from "react-router-dom";

import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/bridge" element={<CalligraphyScreen />}></Route>
        <Route path="/" element={<TypingScreen />}>
          {" "}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
