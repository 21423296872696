import React from "react";
import { useTypewriter } from "react-simple-typewriter";
import "./TypingScreen.scss";
import Instagram from "../assets/icons/instagram.png";
import LinkedIn from "../assets/icons/linkedIn.png";
import Naya from "../assets/icons/naya.png";
import Play from "../assets/icons/play.png";

export const TypingScreen = ({ setShowScreen }) => {
  const { text } = useTypewriter({
    words: [
      "Hi, I’m Saad 👋",
      "it means happiness in arabic 😏",

      "Click the ▶  below to learn how to pronounce it",

      "But if you are struggling, my Starbucks name is ‘Sod’ 😂",

      "I am an engineer by training",

      "But I spent 10 years trying to become a designer",

      "in that time, I helped to design a range of products:",

      "toques (🇨🇦 for winter hat), buildings,  wearables,",

      "furniture, interiors, earthquake retrofits,",

      "graphics, solar farms, and much more",

      "before studying at Harvard’s design school (GSD)",

      "Today, my interests span: ",

      "strategy, tech, and design",

      "At Harvard, I co-founded a company",

      "to enable others to design and create",

      "It is called Naya",

      "Naya means newness in Hindi, Hebrew, and Arabic",

      "The link is below",

      "Although I was born in 🇨🇦",

      "My family spent 5+ generations in Africa",

      "My mum grew up in the Congo",

      "My dad grew up in Tanzania",

      "My ancestors arrived in Zanzibar from India",

      "Their ancestors came from Persia to India",

      "I was lucky to have been raised by 3 grandmothers",

      "and to have worked on all but 2 continents",

      "and to have lived in over 10 cities",

      "Today 🏠  is Boston",

      "As a result of my travels and upbringing, I speak",

      "Kutchi, Gujarati, French, Swahili, and Tajik, and",

      "I’m learning Spanish and Hindi",

      "I feel languages enable creativity",

      "as we exchange new ideas",

      "my deepest fears and challenges",

      "have been fought through creativity",

      "to be creative...",

      "is a persistent, spiritual, and empowering undertaking",

      "My dream is to leave the world a little more creative",

      "than when I arrived",

      "Thanks for reading this monologue",

      "Now, if you share a common vision",

      "I’d love to meet you",

      "Please reach out for a coffee chat ☕️",
    ],
    loop: 0, // Infinite
    deleteSpeed: 1,
    typeSpeed: 50,
    delaySpeed: 400,
  });

  return (
    <div id="typing-text-wrapper">
      <div id="typing-text">
        <span>&nbsp;{text}</span>
      </div>
      <div className="contact">
        &nbsp;
        <img
          src={LinkedIn}
          style={{ marginRight: "1rem" }}
          height="30px"
          onClick={() =>
            window.open("https://www.linkedin.com/in/saad-rajan/", "_blank")
          }
          alt="LinkedIn"
        ></img>
        <img
          src={Instagram}
          style={{ marginRight: "1rem" }}
          height="40px"
          onClick={() =>
            window.open("https://www.instagram.com/saad.makes/", "_blank")
          }
          alt="Instagram"
        ></img>
        <img
          src={Naya}
          style={{ marginRight: "1rem" }}
          onClick={() => window.open("https://naya.studio/", "_blank")}
          height="30px"
          alt="Naya"
        ></img>
        <img
          src={Play}
          style={{ marginRight: "1rem" }}
          onClick={() =>
            window.open("https://www.youtube.com/watch?v=6M3fvV8hsBg", "_blank")
          }
          height="29px"
          alt="Play"
        ></img>
      </div>
    </div>
  );
};
