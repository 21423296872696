import "./Calligraphy.scss";
import { ReactComponent as Bridge } from "../../assets/icons/bridge.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram-white.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedIn-white.svg";
import { ReactComponent as Naya } from "../../assets/icons/naya-white.svg";
import { ReactComponent as ThemeWhite } from "../../assets/icons/theme-white.svg";
import { Row } from "react-bootstrap";

export const CalligraphyScreen = ({ setShowScreen }) => {
  return (
      <div id="calligraphy-screen" >
        <div>
        <Bridge id="bridge" />
        </div>
        <div className="contact">
          <LinkedIn
            style={{ marginRight: "1.5rem" }}
            onClick={() =>
              window.open("https://www.linkedin.com/in/saad-rajan/", "_blank")
            }
          />
          <Instagram
            style={{ marginRight: "1.5rem" }}
            onClick={() =>
              window.open("https://www.instagram.com/saad.makes/", "_blank")
            }
          />
          <Naya
            style={{ marginRight: "1.5rem" }}
            onClick={() => window.open("https://naya.studio/", "_blank")}
          />
          <ThemeWhite
            style={{ marginRight: "1.5rem" }}
            onClick={() => setShowScreen("typing-screen")}
          />
        </div>

        <Row className="text-content">
          <div className="text">
            1. The arabic script above is read as <strong>din</strong> and{" "}
            <strong>dunia</strong> which means the <strong>spritual</strong> and{" "}
            <strong>material</strong>. Life should be a balance of the two.
            <br />
          </div>
          <div className="text">
            2. The bridge is a symbol of transitioning to the other dimension or
            reaching{" "}
            <a href="https://en.wikipedia.org/wiki/Fana_(Sufism)" target={"_blank"}>fanaa</a>{" "}
            (enlightenment).
            <br />
          </div>
          <div className="text">
            3. The bridge is a symbol of bringing together different worlds and
            creating new connections.
            <br />
          </div>
          <div className="text">
            4. The bridge is a unique built-form which values the perspective of
            both the designer and engineer equally.
            <br />
            <br />
            <br />
            This piece was co:designed with{" "}
            <a href="https://www.behance.net/binomar" target={"_blank"}>Mohamed Omar</a> in 2012.
          </div>
        </Row>
      </div>
  );
};
